/** @format */

import { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavItems from "../components/utils/NavItems";
import OffcanvasSec from "../components/utils/OffcanvasSec";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='Header-Selection'>
      <Navbar collapseOnSelect expand='lg'>
        <Container>
          <Navbar.Brand href='/'>
            <img
              style={{ marginTop: -10 }}
              src='assets/logo.png'
              width={70}
              alt=''
            />
            <span className="title fs-1 " data-text='Barbie Coin'>Barbie Coin</span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            onClick={handleShow}
          />
          <Navbar.Collapse
            className='d-none d-lg-block'
            id='responsive-navbar-nav'>
            <NavItems />
            <a href='' target='_blank' rel='noopener noreferrer'>
              {" "}
              <button type='button' className='btn-s '>
                <img
                  src='assets/uni.svg'
                  style={{ width: 30, display: "inline-block" }}
                  alt=''
                />{" "}
                <span>Buy Now</span>
              </button>
            </a>
          </Navbar.Collapse>
        </Container>
        <OffcanvasSec handleClose={handleClose} show={show} />
      </Navbar>
    </div>
  );
}

export default Header;
