/** @format */

import React from "react";

const Partner = () => {
  return (
    <div className='container mt-5 mb-5 partner'>
      <h2 className='title ' data-text='Partner'>
        Partner
      </h2>
      <div className='row text-center mt-5'>
        <div className='col-1'></div>
        <div className='col-sm-2 col-6'>
          <div>
            <a
              href='http://'
              target='_blank'
              rel='noopener noreferrer'
              className='par-bor'>
              <img src='assets/eth.png' className='w-100' alt='' />
            </a>
          </div>
        </div>
        <div className='col-sm-2 col-6'>
          <div>
            <a
              href='http://'
              target='_blank'
              rel='noopener noreferrer'
              className='par-bor'>
              <img src='assets/uniswap.png' className='w-100' alt='' />
            </a>
          </div>
        </div>
        <div className='col-sm-2 col-6'>
          <div>
            <a
              href='http://'
              target='_blank'
              rel='noopener noreferrer'
              className='par-bor'>
              <img
                src='assets/dextools_logo_full_dark.svg'
                className='w-100'
                alt=''
              />
            </a>
          </div>
        </div>
        <div className='col-sm-2 col-6'>
          <div>
            <a
              href='https://twitter.com/Barbietoken_ETH'
              target='_blank'
              rel='noopener noreferrer'
              className='par-bor'>
              <img src='assets/twitter.png' className='w-100' alt='' />
            </a>
          </div>
        </div>
        <div className='col-sm-2 col-6'>
          <div>
            <a
              href='https://t.me/Barbietoken_ETH'
              target='_blank'
              rel='noopener noreferrer'
              className='par-bor'>
              <img src='assets/telegram.png' className='w-100' alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
