/** @format */

import React from "react";

const Roadmap = () => {
  return (
    <>
      <div className='container sec-main pt-5 mt-5' id='roadmap'>
        <div className=' roadmap '>
          <div className='row'>
            <h2 className='title ' data-text='Roadmap'>
              Roadmap
            </h2>
          </div>
          <div className='row partners mt-4'>
            <div className='partner-container'>
              <h5 className='  hero__subtitle fs-1'>Phase 1:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>Launch $Bar Presale</li>
                    <li>BarbieComics</li>
                    <li>1,000+ Holders</li>
                    <li>Pinksale Launch </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className='partner-container'>
              <h5 className='hero__subtitle fs-1'>Phase 2:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>Major Influencer </li>
                    <li>Marketing Push</li>
                    <li>BarbieLand Test</li>
                    <li>Rewards for Holders</li>
                  </ul>
                </span>
              </div>
            </div>

            <div className='partner-container'>
              <h5 className='hero__subtitle fs-1'>Phase 3:</h5>
              <div className='logo-container space-between'>
                <span
                  data-aos='fade-up'
                  data-aos-delay={300}
                  className='aos-init aos-animate'>
                  <ul>
                    <li>Barbie Merch</li>
                    <li>Community Building</li>
                    <li>Big events</li>
                    <li>BarbieLand Beta</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>

          <div className='row partners '>
            <div className='partner-container'>
              <h5 className='  hero__subtitle fs-1'>Phase 4:</h5>
              <div className='logo-container space-between'>
                <span>
                  <ul>
                    <li>BarbieLand Release</li>
                    <li>Comics into Cartoon</li>
                    <li>CEX Listings</li>
                    <li>Hold and Vibe </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className='partner-container'>
              <div className='logo-container space-between'>
                <h5 className='hero__subtitle hero-2  mx-5'>Soon </h5>
                <img src="assets/1.webp" width={100} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
