/** @format */

import React from "react";

const Hero = () => {
  return (
    <div className='hero-section sec-bg2 ' id='hero'>
      <section class='video-container sec-bg2-fixed--height'>
        <div class='content text-white py-5 '>
          <div className='container'>
            <div className='row   m-auto '>
              <div className='col-md-6  mb-5  '>
                <h1
                  data-text='Invest & become Barbie with us'
                  
                  className='title hero__title  mt-5 '>
                  Invest & become Barbie with us
                </h1>
                <h2 className='hero__subtitle'>
                  Barbie token with 60 years of history
                </h2>
                <div
                  className='btn-sec d-flex flex-grow-0 flex-wrap  gap-3 mt-5 align-items-center '
                 >
                  <a href='https://t.me/Barbietoken_ETH' target='_blank' rel='noopener noreferrer'>
                    {" "}
                    <button type='button' class='btn-s'>
                      Join On Telegram
                    </button>
                  </a>
                  <a href='' target='_blank' rel='noopener noreferrer'>
                    {" "}
                    <button type='button' class='btn-s btn-b btn-social'>
                      Buy Barbie
                    </button>
                  </a>
                  <a href='' target='_blank' rel='noopener noreferrer'>
                    {" "}
                    <button type='button' class='btn-s'>
                      Chart
                    </button>
                  </a>
                </div>
              </div>
              <div className='col-md-5 '>
              </div>
            </div>
                <div className='text-end'>
                <p>Inspired by the Barbie Movie 2023</p>

                </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
