/** @format */

import React from "react";

const Fan = () => {
  return (
    <div className=' container-fluid fan-sec '>
      <div className='container py-5'>
        <h2 className='title fans__title' data-text='Million fans around world'>
          Million fans around world
        </h2>
        <img src='assets/map.png' className='w-50 d-block m-auto ' alt='' />
        <div className=' row mt-5 text-center'>
          <div className='col-sm-4'>
            <h5><span className="circle"></span> Barbie - 10 256 561</h5>
          </div>
          <div className='col-sm-4'>
            <h5><span className="circle"></span>  Watch barbie - 987 467</h5>
          </div>
          <div className='col-sm-4'>
            <h5><span className="circle"></span> Turn on barbie - 700 162</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fan;
