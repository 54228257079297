/** @format */

import React from "react";
import Hero from "../../components/elements/home/Hero";
import About from "../../components/elements/home/About";
import Tokenomics from "../../components/elements/home/Tokenomics";
import Partner from "../../components/elements/home/Partner";
import Roadmap from "../../components/elements/home/Roadmap";
import Fan from "../../components/elements/home/Fan";

const index = () => {
  return (
    <>
      <Hero />
      <Fan />
      <About />
      <Tokenomics />
      <Roadmap />
      <Partner />
    </>
  );
};

export default index;
