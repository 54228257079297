/** @format */

import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

const NavItems = ({ handleClose }) => {
  return (
    <>
      <Nav className='ms-auto'>
        <Link onClick={handleClose} className='nav-link' to='/'>
          Home
        </Link>
        <a onClick={handleClose} className='nav-link' href='#roadmap'>
          RoadMap
        </a>
        <a onClick={handleClose} className='nav-link' href='#token-info'>
          Tokenomics
        </a>
        <a onClick={handleClose} className='nav-link' href='#token-info'>
          Chart
        </a>
      </Nav>
    </>
  );
};

export default NavItems;
