/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <div className='Tokenomics-section py-5 position-relative mb-5' id='token-info'>
      <div className='container  '>
        <h2 className='text-center title ' data-text='Tokenomics'>
          Tokenomics
        </h2>
       
        <div className='row mt-5'>
          <div className='col-md-6'>
            <div
              data-da='tokenomics__blocktittle,1,820'
              className='tokenomics__lines'>
              <p>
                Total supply: <span>1.000.000.000</span>
              </p>
              <p class='_small'>
                Ca: <span>0x00000000000000000000000000000000000</span>
              </p>
              <p>
                Tax: <span>1/1 %</span>
              </p>
            </div>
            <div class='tokenomics__block'>
              <div className='tokenomics__10'>10%</div>
              <div className='tokenomics__text'>
                Of tokens for creating BarbieLand Cashback system for top
                Holders
              </div>
              <a href='' class='btn-s btn-b' target='_blank'>
                <span>BUY $BAR</span>
              </a>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='barcontainer'>
              <div className='bar' style={{ height: "33%" }}></div>
              <p className='bar-pra'>Liqudity: 33%</p>
            </div>
            <div className='barcontainer'>
              <div className='bar' style={{ height: "10%" }}></div>
              <p className='bar-pra'>CEX 10%</p>
            </div>
            <div className='barcontainer'>
              <div className='bar' style={{ height: "2%" }}></div>
              <p className='bar-pra'>Advising: 2%</p>
            </div>
            <div className='barcontainer'>
              <div className='bar' style={{ height: "55%" }}></div>
              <p className='bar-pra'>Presale: 55%</p>
            </div>
          </div>
        </div>
        <div className="tokenomics__home">
					<picture>
						<source srcset="assets/home.webp" type="image/webp"/>
						<img src="assets/home.webp" alt=""/>
					</picture>
				</div>
      </div>
    </div>
  );
};

export default Tokenomics;
