/** @format */

import React from "react";
import { FaEnvelope, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <section className='follow'>
      <div className='follow__home'>
        <picture>
          <img src='assets/home1.png' alt='' />
        </picture>
      </div>
      <div className='follow__car'>
        <picture>
          <img src='assets/bar.png' alt='' />
        </picture>
      </div>
      <div className='follow__bg'>
        <picture>
          <img src='assets/bg.jpg' alt='' />
        </picture>
      </div>
      <div className='follow__content'>
        <div className='_container'>
          <div className='follow__coins'>
            <picture>
              <img src='assets/logo.png' alt='' />
            </picture>
          </div>
          <h3 data-text='Follow Barbie' className='title follow__title'>
            Follow Barbie
          </h3>
          <div className='follow__social'>
            <a
              href='https://twitter.com/Barbietoken_ETH'
              className='icon-tw'
              target='_blank'
            />

            <a
              href='https://t.me/Barbietoken_ETH'
              className='icon-tg'
              target='_blank'
            />
            <a href='#' className='icon-eth' target='_blank' />
          </div>
          <div className='follow__text'>
            <p>© Copyright 2023. All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
