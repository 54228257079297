/** @format */

import React from "react";

const About = () => {
  return (
    <div className='About-section py-5' id='about'>
      <div className='container'>
        <h1
          className='text-center title fans__title '
          data-text='Why Barbie Coin?'>
          Why Barbie Coin?
        </h1>

        <div className='row align-items-center mt-5'>
          <div className='col-md-6 m-auto'>
            <div className=" rounded-4">

            <img
              src='assets/bgs1.png'
              alt=''
              className='img-fluid m-auto d-block w-75 rounded-4 img-br'
              data-aos='flip-right'
              />
              </div>
          </div>
          <div className='col-md-6'>
            <h4 className='hero__subtitle fs-1 '>
              <strong>New chapter after every daily ATH</strong>
            </h4>
            <p>
              Get ready for an exciting journey that intertwines the world of
              Barbie with the cryptocurrency.
              <br />
              <br />
              We are thrilled to announce that with every new All-Time High
              (ATH) achievement in BarbieToken's price, a brand-new chapter will
              be unlocked in the captivating BarbieComics series.
            </p>
            <ul>
              <li className='title_about '>
                Invest in BarbieToken today and let your dreams soar to new
                heights
              </li>
              <li className='title_about '>
                The future awaits—join us on this extraordinary journey
              </li>
              <li className='title_about '>
                Hurry up to buy at a bargain price
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
